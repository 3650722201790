import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import QuantityBox from "../../Components/QuantityBox";
import { IoIosClose } from "react-icons/io";
import Button from "@mui/material/Button";

import emprtCart from "../../assets/images/emptyCart.png";
import { MyContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import { deleteData, editData, fetchDataFromApi } from "../../utils/api";
import { IoBagCheckOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const [cartData, setCartData] = useState([]);
  const [productQuantity, setProductQuantity] = useState();
  let [cartFields, setCartFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQuantity, setselectedQuantity] = useState();
  const [chengeQuantity, setchengeQuantity] = useState(0);
  const [isLogin, setIsLogin] = useState(false);

  const [otherCharges, setOtherCharges] = useState(0);

  const context = useContext(MyContext);
  const history = useNavigate();

  var otherCharges_ = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
    const token = localStorage.getItem("token");
    if (token !== "" && token !== undefined && token !== null) {
      setIsLogin(true);
    } else {
      history("/signIn");
    }

    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/cart?buyerCode=${user?.userId}`).then((res) => {
      setCartData(res);

      res?.length !== 0 &&
        res?.map((item) => {
          otherCharges_ +=
            parseInt(item?.otherCharges) * parseInt(item?.quantity);
        });

      setOtherCharges(otherCharges_);
    });
  }, []);

  const removeItem = (id, vendorCode, productCode, quantity) => {
    setIsLoading(true);
    deleteData(`/api/cart/${id}`).then((res) => {
      context.setAlertBox({
        open: true,
        error: false,
        msg: "item removed from cart!",
      });

      const user = JSON.parse(localStorage.getItem("user"));

      fetchDataFromApi(`/api/cart?buyerCode=${user?.userId}`).then((res) => {
        setCartData(res);
        setIsLoading(false);

        fetchDataFromApi(
          `/api/vendor/details/vendorCode?vendorCode=${vendorCode}&productId=${productCode}`
        ).then((resp) => {
          const data = {
            productId: resp[0]?.productId,
            productName: resp[0]?.productName,
            vendorCode: resp[0]?.vendorCode,
            proposedPrice: resp[0]?.proposedPrice,
            stock: resp[0]?.stock,
            unit: resp[0]?.unit,
            price: resp[0]?.price,
            deliveryDate: resp[0]?.deliveryDate,
            balanceStock: parseInt(resp[0]?.balanceStock) + parseInt(quantity),
            isActive: resp[0]?.isActive,
          };

          editData(`/api/vendor/details/${resp[0]?._id}`, data).then(
            (response) => {}
          );
        });
      });

      context.getCartData();
    });
  };

  return (
    <>
      <section className="section cartPage">
        <div className="container">
          <h2 className="hd mb-1">Your Cart</h2>
          <p>
            There are <b className="text-red">{cartData?.length}</b> products in
            your cart
          </p>

          {cartData?.length !== 0 ? (
            <div className="row">
              <div className="col-md-9 pr-5">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="30%">Product</th>
                        <th width="10%">Quantity</th>
                        <th width="20%">Price Per Unit</th>
                        <th width="10%">Unit</th>
                        <th width="15%">Sub Total</th>
                        <th width="25%">Other Charges</th>
                        <th width="10%">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData?.length !== 0 &&
                        cartData?.map((item, index) => {
                          return (
                            <tr>
                              <td width="30%">
                                <Link to={`/product/${item?.productCode}`}>
                                  <div className="d-flex align-items-center cartItemimgWrapper">
                                    <div className="imgWrapper">
                                      <img
                                        src={item?.productImage}
                                        className="w-100"
                                        alt={item?.productName}
                                      />
                                    </div>

                                    <div className="info px-3">
                                      <h6 className="text-capitalize">
                                        {item?.productName?.substr(0, 70) +
                                          "..."}
                                      </h6>
                                    </div>
                                  </div>
                                </Link>
                              </td>
                              <td width="10%"> {item?.quantity}</td>
                              <td width="20%">{item?.pricePerUnit}</td>
                              <td width="10%"> {item?.unit}</td>
                              <td width="15%">
                                {" "}
                                {parseInt(item?.quantity) *
                                  parseInt(item?.pricePerUnit)}{" "}
                              </td>
                              <td width="25%">
                                {" "}
                                {parseInt(item?.otherCharges) *
                                  parseInt(item?.quantity)}
                              </td>
                              <td width="10%">
                                <span
                                  className="remove"
                                  onClick={() =>
                                    removeItem(
                                      item?._id,
                                      item?.vendorCode,
                                      item?.productCode,
                                      item?.quantity
                                    )
                                  }
                                >
                                  <IoIosClose />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card border p-3 cartDetails">
                  <h4>CART TOTALS</h4>

                  <div className="d-flex align-items-center mb-3">
                    <span>Subtotal</span>
                    <span className="ml-auto text-red font-weight-bold">
                      {(context.cartData?.length !== 0
                        ? context.cartData
                            ?.map(
                              (item) =>
                                parseInt(item?.quantity) *
                                parseInt(item?.pricePerUnit)
                            )

                            .reduce((total, value) => total + value, 0)
                        : 0
                      )?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <span>Other Charges</span>
                    <span className="ml-auto">
                      <b>{otherCharges}</b>
                    </span>
                  </div>

                  <div className="d-flex align-items-center mb-3">
                    <span>Estimate for</span>
                    <span className="ml-auto">
                      <b>United Kingdom</b>
                    </span>
                  </div>

                  <div className="d-flex align-items-center">
                    <span>Total</span>
                    <span className="ml-auto text-red font-weight-bold">
                      {(context.cartData?.length !== 0
                        ? context.cartData
                            ?.map(
                              (item) =>
                                parseInt(item?.quantity) *
                                parseInt(item?.pricePerUnit)
                            )
                            .reduce((total, value) => total + value, 0) +
                          otherCharges
                        : 0
                      )?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </span>
                  </div>

                  <br />
                  <Link to="/checkout">
                    <Button className="btn-blue bg-red btn-lg btn-big">
                      <IoBagCheckOutline /> &nbsp; Checkout
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="empty d-flex align-items-center justify-content-center flex-column">
              <img src={emprtCart} width="150" />
              <h3>Your Cart is currently empty</h3>
              <br />
              <Link to="/">
                {" "}
                <Button className="btn-blue bg-red btn-lg btn-big btn-round">
                  <FaHome /> &nbsp; Continue Shopping
                </Button>
              </Link>
            </div>
          )}
        </div>
      </section>

      {isLoading === true && <div className="loadingOverlay"></div>}
    </>
  );
};

export default Cart;
