import React, { useEffect, useState } from "react";
import { editData, fetchDataFromApi, postData } from "../../utils/api";
import { useParams } from "react-router-dom";

const PaymentSuccess = () => {
  const { id } = useParams();
  const transactionId = window.location.href.split("=")[1];

  useEffect(() => {
    fetchDataFromApi(`/api/orders/${id}`).then((res) => {
      const updatedOrder = {
        name: res.name,
        phoneNumber: res.phone,
        address: res.address,
        pincode: res.pincode,
        amount: res.amount,
        paymentId: res.paymentId,
        email: res.email,
        userid: res.userId,
        products: res.products,
        paymentId: transactionId,
      };

      editData(`/api/orders/${id}`, updatedOrder).then((response) => {});
    });
  }, [id]);

  return (
    <>
      <section>
        <h1>Payment Success</h1>
      </section>
    </>
  );
};

export default PaymentSuccess;
