import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";

import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaRegUser } from "react-icons/fa6";
import { GoPencil } from "react-icons/go";


const ContactUs = () => {


    useEffect(() => {
        window.scrollTo(0,0)
    }, []);


    return (
        <>

            <section className="section cartPage get_in_touch pb-0 pt-0 contact">
                <div className="container">


                <div className="row align-items-center">
                <div className="col-md-7 pl-5">		
                <div className="box pl-4" style={{borderLeft:'3px solid #ff6f27'}}>	
                    <h2 className="hd text-uppercase mb-4">Head office, noida</h2>
                    <p className="font-16">
                        1557, 25th Main Rd, MCHS Colony, Stage 2, BTM Layout, <br/>
    Bengaluru, Karnataka 560076
                    </p>
                    
                    <h4 className="font-18 d-flex align-items-center text-light">
                    <MdOutlinePhone className="mr-2"/>  +91 (254) 125 1111 </h4>
                    <h4 className="font-18 d-flex align-items-center text-light"><MdOutlineMailOutline className="mr-2"/>  info@atslabinfotech.com </h4>
                    
                    <h4 className="font-18 mt-4">Follow Us</h4>
                    <ul className="list list-inline socials-color">
                        <li className="list-inline-item">
                            <a href="#" target="_blank" style={{background:'#3b5998'}} className="d-flex align-items-center justify-content-center rounded-circle"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                        </li>
                        <li className="list-inline-item">
                            <a href="#" target="_blank" style={{background:'#0e76a8'}} className="d-flex align-items-center justify-content-center rounded-circle"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </li>
                        <li className="list-inline-item">
                            <a href="#" target="_blank" style={{background:'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)'}} className="d-flex align-items-center justify-content-center rounded-circle"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                        </li>
                        <li className="list-inline-item">
                            <a href="#" target="_blank" style={{background:'#00acee'}}  className="d-flex align-items-center justify-content-center rounded-circle"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                        </li>
            
                        <li className="list-inline-item">
                            <a href="#" target="_blank" style={{background:'#cf1e1e'}} className="d-flex align-items-center justify-content-center rounded-circle"><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                        </li>																				
                    </ul>
                    
                </div>
                </div>
                
                <div className="col-md-5 pt-5">
                    
                    <div className="p-4 m-auto card bg-gray position-relative">
                   
                    <h4 className="font-26 mb-0 hd" style={{lineHeight:'30px'}}>Connect with us</h4>
                    <p className="font-18 mt-0">Drop us a message and we’ll get back to you</p>
                        <form className="form getInTouchForm position-relative contact_form" id="getInTouchForm">
                        
                            <div className="form-group icon">
                                <FaRegUser/>
                                <input type="text" className="input form-control" placeholder="Name" required=""/>
                                <div className="invalid-feedback">Please enter Name</div>							
                            </div>
                            
                            <div className="row flex_col">
                                <div className="col-sm-6 col-md-6 pr-0">
                                    <div className="form-group icon">
                                    <MdOutlineMailOutline/>
                                        
                                        <input type="text" className="input" placeholder="Email" required=""/>
                                        <div className="invalid-feedback">Please enter Email</div>		
                                    </div>	
                                </div>
                                
                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group icon">
                                    <MdOutlinePhone/>
                                    <input type="text" className="input" placeholder="Mobile" required=""/>
                                    <div className="invalid-feedback">Please enter Mobile</div>
                                
                                </div>							
                                </div>	
                                                        
                            </div>
                            
                            
    
                            <div className="form-group icon h-auto">
                                <GoPencil/>
                                <textarea className="input" placeholder="Message" required=""></textarea>
                                <div className="invalid-feedback">Please enter Message</div>
                            </div>		
                            <div className="text-center mt-4 mb-2">
                                <Button type="submit" className="btn-blue bg-red btn-lg btn-big  w-100" >Submit</Button>
                            </div>															
                        </form>	
                    </div>
                </div>
            </div>
            
            

                </div>
            </section>

        
        </>
    )
}

export default ContactUs;