import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import Button from "@mui/material/Button";
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../../App";

const QuantityBox = (props) => {
  const [inputVal, setInputVal] = useState(1);

  const context = useContext(MyContext);

  useEffect(() => {
    if (
      props?.value !== undefined &&
      props?.value !== null &&
      props?.value !== ""
    ) {
      setInputVal(parseInt(props?.value));
    }
  }, [props.value]);

  useEffect(() => {
    setInputVal(props?.item?.countInStock);
  }, [props?.item]);

  const minus = () => {
    let stock = parseInt(props.item.countInStock);
    if (inputVal <= stock) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "The quantity is greater than product count in stock",
      });
    } else {
      if (inputVal !== 1 && inputVal > 0) {
        setInputVal(inputVal - 1);
      }

      context.setAlertBox({
        open: false,
      });
    }
  };

  const plus = () => {
    setInputVal(inputVal + 1);
  };

  const changeQty = (e) => {
    setInputVal(e.target.value);
  };

  const updateQty = (e) => {
    let stock = parseInt(props.item.countInStock);
    let inputVal = parseInt(e.target.value);
    if (inputVal <= stock) {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "The quantity is greater than product count in stock",
      });
      setInputVal(stock);
    } else {
        setInputVal(parseInt(e.target.value));
    }
   
  };

  useEffect(() => {
    if (props.quantity) {
      props.quantity(inputVal);
    }

    if (props.selectedItem) {
      props.selectedItem(props.item, inputVal);
    }
  }, [inputVal]);

  return (
    <div className="quantityDrop d-flex align-items-center">
      <Button onClick={minus} disabled={props.disabled}>
        <FaMinus />
      </Button>
      <input
        type="number"
        value={inputVal}
        onChange={changeQty}
        onBlur={updateQty}
        disabled={props.disabled}
      />
      <Button onClick={plus} disabled={props.disabled}>
        <FaPlus />
      </Button>
    </div>
  );
};

export default QuantityBox;
