// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerSection{padding: 25px 0px; padding-bottom: 0px; display: grid; gap: 15px;}
.bannerSection .col_{width: 100%;}
.bannerSection .box{overflow: hidden; border-radius: 15px; cursor: pointer; display: block;}
.bannerSection .box img{height: auto !important; transition:all 0.3s;}
.bannerSection .box:hover img{transform: scale(1.1); }`, "",{"version":3,"sources":["webpack://./src/Components/banners/style.css"],"names":[],"mappings":"AAAA,eAAe,iBAAiB,EAAE,mBAAmB,EAAE,aAAa,EAAE,SAAS,CAAC;AAChF,qBAAqB,WAAW,CAAC;AACjC,oBAAoB,gBAAgB,EAAE,mBAAmB,EAAE,eAAe,EAAE,cAAc,CAAC;AAC3F,wBAAwB,uBAAuB,EAAE,mBAAmB,CAAC;AACrE,8BAA8B,qBAAqB,EAAE","sourcesContent":[".bannerSection{padding: 25px 0px; padding-bottom: 0px; display: grid; gap: 15px;}\r\n.bannerSection .col_{width: 100%;}\r\n.bannerSection .box{overflow: hidden; border-radius: 15px; cursor: pointer; display: block;}\r\n.bannerSection .box img{height: auto !important; transition:all 0.3s;}\r\n.bannerSection .box:hover img{transform: scale(1.1); }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
