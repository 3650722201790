import React from 'react';

const PaymentFailed=()=>{
    return(
        <>
            <h1>Payment Success</h1>
        </>
    )
}

export default PaymentFailed;