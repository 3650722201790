import ProductZoom from "../../Components/ProductZoom";
import Rating from "@mui/material/Rating";
import QuantityBox from "../../Components/QuantityBox";
import Button from "@mui/material/Button";
import { BsCartFill } from "react-icons/bs";
import { useContext, useEffect, useRef, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlineCompareArrows } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import RelatedProducts from "./RelatedProducts";

import { useParams } from "react-router-dom";
import { editData, fetchDataFromApi, postData } from "../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import { MyContext } from "../../App";
import { FaHeart } from "react-icons/fa";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const ProductDetails = () => {
  const [activeSize, setActiveSize] = useState(null);
  const [activeTabs, setActiveTabs] = useState(0);
  const [productData, setProductData] = useState([]);
  const [relatedProductData, setRelatedProductData] = useState([]);
  const [recentlyViewdProducts, setRecentlyViewdProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewsData, setreviewsData] = useState([]);
  const [isAddedToMyList, setSsAddedToMyList] = useState(false);

  let [cartFields, setCartFields] = useState({});
  let [productQuantity, setProductQuantity] = useState();
  const [tabError, setTabError] = useState(false);

  const [vendorUserCode, setVendorUserCode] = useState("");
  const [vendorPrice, setVendorPrice] = useState("");
  const [deliveryDate, setDeliveryDate] = useState();
  const [isActiveCheck, setIsActiveCheck] = useState(false);
  const [isSavedVendorDetails, setIsSavedVendorDetails] = useState(false);
  const [updatedQty, setUpdatedQty] = useState(0);
  const [isDisabledEle, setIsDisabledEle] = useState(false);
  const [versionNumber, setIsversionNumber] = useState(1);
  const [isEditInfo, setisEditInfo] = useState(false);
  const [currentSavedVendorInfo, setCurrentSavedVendorInfo] = useState(false);
  const [userCode, setUserCode] = useState();
  const [savedCurrentVendorId, setSavedCurrentVendorId] = useState();
  const [currentSavedVendetailsInfo, setCurrentSavedVendetailsInfo] =
    useState();

  const [priceValue, setPriceValue] = useState(0);
  const [qtyValue, setQtyValue] = useState();
  const [inputDateValue, setInputDateValue] = useState();

  const [vendorData, setVendorData] = useState({
    vendorCode: 0,
    productId: "",
    proposedPrice: "",
    stock: 0,
    unit: "",
    price: "",
    deliveryDate: "",
    balanceStock: "",
    versionNumber: "",
    isActive: false,
  });

  const { id } = useParams();

  const inputDate = useRef();

  var userInfo = {};

  const context = useContext(MyContext);

  const isActive = (index) => {
    setActiveSize(index);
    setTabError(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setActiveSize(null);

    getDate();

    const userInfo = JSON.parse(localStorage.getItem("user"));
    setUserCode(userInfo?.userCode);

    fetchDataFromApi(`/api/products/${id}`).then((res) => {
      setProductData(res);

      if (
        res?.productRam.length === 0 &&
        res?.productWeight.length === 0 &&
        res?.size.length === 0
      ) {
        setActiveSize(1);
      }

      fetchDataFromApi(`/api/products?subCatId=${res?.subCatId}`).then(
        (res) => {
          const filteredData = res?.products?.filter((item) => item.id !== id);
          setRelatedProductData(filteredData);
        }
      );

      getVendorDetailsOnPageLoad();
    });

    const getVendorDetailsOnPageLoad = () => {
      const userInfo = JSON.parse(localStorage.getItem("user"));
      fetchDataFromApi(
        `/api/vendor/details?prodId=${id}&vendorCode=${userInfo?.userCode}`
      ).then((res) => {
        if (res?.length > 0) {
          setCurrentSavedVendetailsInfo(res[0]);
          setVendorPrice(parseInt(res[0]?.price));
          setPriceValue(parseInt(res[0]?.price));
          setQtyValue(parseInt(res[0]?.stock));
          setInputDateValue(res[0]?.deliveryDate);
          setIsSavedVendorDetails(true);
          setUpdatedQty(parseInt(res[0]?.balanceStock));
          setIsDisabledEle(true);
        } else {
          setIsSavedVendorDetails(false);
          setIsDisabledEle(false);
        }
      });
    };

    function getDate() {
      var todaydate = new Date();
      var day = todaydate.getDate();
      var month = todaydate.getMonth() + 1;
      var year = todaydate.getFullYear();

      var strMonth = "";

      if (month < 10) {
        strMonth = "0" + month;
      } else {
        strMonth = month;
      }

      var datestring = year + "-" + strMonth + "-" + day;
      setDeliveryDate(datestring);
      setInputDateValue(datestring);
    }

    fetchDataFromApi(`/api/productReviews?productId=${id}`).then((res) => {
      setreviewsData(res);
    });

    const user = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(
      `/api/my-list?productId=${id}&userId=${user?.userId}`
    ).then((res) => {
      if (res.length !== 0) {
        setSsAddedToMyList(true);
      }
    });

    setPriceValue(0);
  }, [id]);

  const [rating, setRating] = useState(1);
  const [reviews, setReviews] = useState({
    productId: "",
    customerName: "",
    customerId: "",
    review: "",
    customerRating: 0,
  });

  const onChangeInput = (e) => {
    setReviews(() => ({
      ...reviews,
      [e.target.name]: e.target.value,
    }));
  };

  const changeRating = (e) => {
    setRating(e.target.value);
    reviews.customerRating = e.target.value;
  };

  const addReview = (e) => {
    e.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));

    reviews.customerName = user?.name;
    reviews.customerId = user?.userId;
    reviews.productId = id;

    setIsLoading(true);

    postData("/api/productReviews/add", reviews).then((res) => {
      setIsLoading(false);

      reviews.customerRating = 1;

      setReviews({
        review: "",
        customerRating: 1,
      });

      fetchDataFromApi(`/api/productReviews?productId=${id}`).then((res) => {
        setreviewsData(res);
      });
    });
  };

  const quantity = (val) => {
    setProductQuantity(val);
  };

  const addtoCart = () => {
    if (activeSize !== null) {
      const user = JSON.parse(localStorage.getItem("user"));

      cartFields.productTitle = productData?.name;
      cartFields.image = productData?.images[0];
      cartFields.rating = productData?.rating;
      cartFields.price = productData?.price;
      cartFields.quantity = productQuantity;
      cartFields.subTotal = parseInt(productData?.price * productQuantity);
      cartFields.productId = productData?.id;
      cartFields.countInStock = productData?.countInStock;
      cartFields.userId = user?.userId;

      context.addToCart(cartFields);
    } else {
      setTabError(true);
    }
  };

  const selectedItem = () => {};

  const gotoReviews = () => {
    window.scrollTo({
      top: 550,
      behavior: "smooth",
    });

    setActiveTabs(2);
  };

  const addToMyList = (id) => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== undefined && user !== null && user !== "") {
      const data = {
        productTitle: productData?.name,
        image: productData?.images[0],
        rating: productData?.rating,
        price: productData?.price,
        productId: id,
        userId: user?.userId,
      };
      postData(`/api/my-list/add/`, data).then((res) => {
        if (res.status !== false) {
          context.setAlertBox({
            open: true,
            error: false,
            msg: "the product added in my list",
          });

          fetchDataFromApi(
            `/api/my-list?productId=${id}&userId=${user?.userId}`
          ).then((res) => {
            if (res.length !== 0) {
              setSsAddedToMyList(true);
            }
          });
        } else {
          context.setAlertBox({
            open: true,
            error: true,
            msg: res.msg,
          });
        }
      });
    } else {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please Login to continue",
      });
    }
  };

  const selectDate = (e) => {
    setDeliveryDate(e.target.value);
    setInputDateValue(e.target.value);
  };

  const isCheckActive = (e) => {
    setIsActiveCheck(e.target.checked);
  };

  const getVendorInfo = (prodId) => {
    fetchDataFromApi(
      `/api/vendor/details?prodId=${prodId}&vendorCode=${userCode}`
    ).then((res) => {
      if (res.length > 0) {
        setSavedCurrentVendorId(res[0]?.id);
      }
    });
  };

  const saveVendor = () => {
    if (priceValue !== 0) {
      userInfo = JSON.parse(localStorage.getItem("user"));

      const data = {
        productId: id,
        productName: productData?.name,
        vendorCode: userInfo?.userCode,
        proposedPrice: productData?.price,
        stock: productQuantity,
        unit: productData?.unit,
        price: vendorPrice,
        deliveryDate: deliveryDate,
        balanceStock: productQuantity,
        versionNumber: versionNumber,
        isActive: isActiveCheck,
      };

      setIsSavedVendorDetails(true);
      setIsDisabledEle(true);

      if (isEditInfo === false) {
        postData("/api/vendor/details/add", data).then((res) => {
          setUpdatedQty(productQuantity);
          if (res?.error !== false) {
            context.setAlertBox({
              open: true,
              msg: res?.msg,
              error: false,
            });
          }
        });
      } else {
        if (savedCurrentVendorId !== "") {
          fetchDataFromApi(
            `/api/vendor/details?prodId=${id}&vendorCode=${userInfo?.userCode}`
          ).then((res) => {
            if (res?.length > 0) {
              setCurrentSavedVendetailsInfo(res[0]);
            }
          });

          userInfo = JSON.parse(localStorage.getItem("user"));

          fetchDataFromApi(
            `/api/vendor/details?prodId=${id}&vendorCode=${userInfo?.userCode}`
          ).then((res) => {
            if (res?.length > 0) {
              //setCurrentSavedVendetailsInfo(res[0]);
              setVendorPrice(priceValue);
              alert("vendorPrice", vendorPrice);

              const data = {
                productId: id,
                productName: productData?.name,
                vendorCode: userInfo?.userCode,
                proposedPrice: productData?.price,
                stock: productQuantity,
                unit: productData?.unit,
                price: vendorPrice,
                deliveryDate: deliveryDate,
                balanceStock:
                  parseInt(res[0]?.balanceStock) + parseInt(productQuantity),
                versionNumber: parseInt(res[0]?.versionNumber) + 1,
                isActive: isActiveCheck,
              };

      
              const dataEdit = {
                productId: res[0]?.productId,
                productName: res[0]?.name,
                vendorCode: res[0]?.vendorCode,
                proposedPrice: res[0]?.proposedPrice,
                stock: res[0]?.stock,
                unit: res[0]?.unit,
                price: res[0]?.price,
                deliveryDate: res[0]?.deliveryDate,
                balanceStock: res[0]?.balanceStock,
                versionNumber: res[0]?.versionNumber,
                isActive: res[0]?.isActive,
              };

             // alert(parseInt(res[0]?.balanceStock) + parseInt(productQuantity));

              editData(
                `/api/vendor/details/${savedCurrentVendorId}`,
                data
              ).then((response) => {
                setUpdatedQty(parseInt(data?.balanceStock));
                context.setAlertBox({
                  open: true,
                  msg: "The details edit successfully!",
                  error: false,
                });

                postData("/api/vendor/detailsHistory/add", dataEdit).then(
                  (res) => {}
                );
              });
            }
          });
        }
      }
    } else {
      context.setAlertBox({
        open: true,
        msg: "Please Add price",
        error: true,
      });
    }
  };

  return (
    <>
      <section className="productDetails section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 pl-5 part1">
              <ProductZoom
                images={productData?.images}
                discount={productData?.discount}
              />
            </div>

            <div className="col-md-7 pl-5 pr-5 part2">
              <h2 className="hd text-capitalize">{productData?.name}</h2>
              <ul className="list list-inline d-flex align-items-center">
                <li className="list-inline-item">
                  <div className="d-flex align-items-center">
                    <span className="text-light mr-2">Brands : </span>
                    <span>{productData?.brand}</span>
                  </div>
                </li>

                <li className="list-inline-item">
                  <div className="d-flex align-items-center">
                    <Rating
                      name="read-only"
                      value={parseInt(productData?.rating)}
                      precision={0.5}
                      readOnly
                      size="small"
                    />

                    <span
                      className="text-light cursor ml-2"
                      onClick={gotoReviews}
                    >
                      {reviewsData?.length} Review
                    </span>
                  </div>
                </li>
              </ul>

              <div className="d-flex align-items-center info mb-3">
                <span className="netPrice text-danger mr-4">
                  Proposed Price: {productData?.price}
                </span>

                <FormControlLabel
                  control={<Switch onChange={isCheckActive} defaultChecked />}
                  label="Active"
                  disabled={isDisabledEle === true ? true : false}
                />
              </div>

              <hr />

              <div className="d-flex align-items-center">
                <div className=" mr-3">
                  <span className="badge badge-primary">STOCK</span>
                </div>
                <QuantityBox
                  quantity={quantity}
                  item={productData}
                  selectedItem={selectedItem}
                  value={qtyValue}
                  disabled={isDisabledEle === true ? true : false}
                />
              </div>

              <br />
              <div className="d-flex align-items-center box_">
                <span className="col_">UNIT</span>
                <span className="text-capitalize">{productData?.unit}</span>
              </div>

              <div className="d-flex align-items-center box_">
                <span className="col_">PRICE</span>
                <input
                  type="number"
                  className="inputBox"
                  onChange={(e) => {
                    setVendorPrice(e.target.value);
                    setPriceValue(e.target.value);
                  }}
                  value={priceValue}
                  disabled={isDisabledEle === true ? true : false}
                />
              </div>

              <div className="d-flex align-items-center box_">
                <span className="col_">DELIVERY DATE</span>
                <input
                  type="date"
                  className="inputBox"
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => selectDate(e)}
                  value={inputDateValue}
                  disabled={isDisabledEle === true ? true : false}
                  ref={inputDate}
                />
              </div>
              <br />

              <div className="d-flex align-items-center">
                <Button
                  className="btn-blue btn-round bg-red"
                  onClick={saveVendor}
                  disabled={isDisabledEle === true ? true : false}
                >
                  SAVE
                </Button>
                {isSavedVendorDetails === true && (
                  <Button
                    className="btn-border btn-round ml-3"
                    onClick={() => {
                      setisEditInfo(true);
                      setIsDisabledEle(false);
                      getVendorInfo(id);
                    }}
                  >
                    EDIT
                  </Button>
                )}
              </div>

              <hr />

              <div className="d-flex align-items-center box_">
                <span className="col_">BALANCE STOCK</span>

                <span>{isSavedVendorDetails === true ? updatedQty : 0}</span>
              </div>
              <br />

              {
                // productData?.countInStock >= 1 ?
                //     <span className="badge badge-success">IN STOCK</span>
                //     :
                //     <span className="badge badge-danger">OUT OF STOCK</span>
              }

              {
                //  <p className="mt-3">Rs: {productData?.description}</p>
              }

              {productData?.productRam?.length !== 0 && (
                <div className="productSize d-flex align-items-center">
                  <span>RAM:</span>
                  <ul
                    className={`list list-inline mb-0 pl-4 ${
                      tabError === true && "error"
                    }`}
                  >
                    {productData?.productRam?.map((item, index) => {
                      return (
                        <li className="list-inline-item">
                          <a
                            className={`tag ${
                              activeSize === index ? "active" : ""
                            }`}
                            onClick={() => isActive(index)}
                          >
                            {item}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {productData?.size?.length !== 0 && (
                <div className="productSize d-flex align-items-center">
                  <span>Size:</span>
                  <ul
                    className={`list list-inline mb-0 pl-4 ${
                      tabError === true && "error"
                    }`}
                  >
                    {productData?.size?.map((item, index) => {
                      return (
                        <li className="list-inline-item">
                          <a
                            className={`tag ${
                              activeSize === index ? "active" : ""
                            }`}
                            onClick={() => isActive(index)}
                          >
                            {item}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {productData?.productWeight?.length !== 0 && (
                <div className="productSize d-flex align-items-center">
                  <span>Weight:</span>
                  <ul
                    className={`list list-inline mb-0 pl-4 ${
                      tabError === true && "error"
                    }`}
                  >
                    {productData?.productWeight?.map((item, index) => {
                      return (
                        <li className="list-inline-item">
                          <a
                            className={`tag ${
                              activeSize === index ? "active" : ""
                            }`}
                            onClick={() => isActive(index)}
                          >
                            {item}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {
                //    <div className="d-flex align-items-center mt-3 actions_">
                //     <div className="d-flex align-items-center btnActions">
                //       <Button
                //         className="btn-blue btn-lg btn-big btn-round bg-red"
                //         onClick={() => addtoCart()}
                //       >
                //         <BsCartFill /> &nbsp;
                //         {context.addingInCart === true
                //           ? "adding..."
                //           : " Add to cart"}
                //       </Button>
                //       <Tooltip
                //         title={`${
                //           isAddedToMyList === true
                //             ? "Added to Wishlist"
                //             : "Add to Wishlist"
                //         }`}
                //         placement="top"
                //       >
                //         <Button
                //           className={`btn-blue btn-lg btn-big btn-circle ml-4`}
                //           onClick={() => addToMyList(id)}
                //         >
                //           {isAddedToMyList === true ? (
                //             <FaHeart className="text-danger" />
                //           ) : (
                //             <FaRegHeart />
                //           )}
                //         </Button>
                //       </Tooltip>
                //       <Tooltip title="Add to Compare" placement="top">
                //         <Button className="btn-blue btn-lg btn-big btn-circle ml-2">
                //           <MdOutlineCompareArrows />
                //         </Button>
                //       </Tooltip>
                //     </div>
                //   </div>
              }
            </div>
          </div>

          <br />

          <div className="card mt-5 p-5 detailsPageTabs">
            <div className="customTabs">
              <ul className="list list-inline">
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 0 && "active"}`}
                    onClick={() => {
                      setActiveTabs(0);
                    }}
                  >
                    Description
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 1 && "active"}`}
                    onClick={() => {
                      setActiveTabs(1);
                    }}
                  >
                    Additional info
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    className={`${activeTabs === 2 && "active"}`}
                    onClick={() => {
                      setActiveTabs(2);
                    }}
                  >
                    Reviews ({reviewsData?.length})
                  </Button>
                </li>
              </ul>

              <br />

              {activeTabs === 0 && (
                <div className="tabContent">{productData?.description}</div>
              )}

              {activeTabs === 1 && (
                <div className="tabContent">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tbody>
                        <tr className="stand-up">
                          <th>Stand Up</th>
                          <td>
                            <p>35″L x 24″W x 37-45″H(front to back wheel)</p>
                          </td>
                        </tr>
                        <tr className="folded-wo-wheels">
                          <th>Folded (w/o wheels)</th>
                          <td>
                            <p>32.5″L x 18.5″W x 16.5″H</p>
                          </td>
                        </tr>
                        <tr className="folded-w-wheels">
                          <th>Folded (w/ wheels)</th>
                          <td>
                            <p>32.5″L x 24″W x 18.5″H</p>
                          </td>
                        </tr>
                        <tr className="door-pass-through">
                          <th>Door Pass Through</th>
                          <td>
                            <p>24</p>
                          </td>
                        </tr>
                        <tr className="frame">
                          <th>Frame</th>
                          <td>
                            <p>Aluminum</p>
                          </td>
                        </tr>
                        <tr className="weight-wo-wheels">
                          <th>Weight (w/o wheels)</th>
                          <td>
                            <p>20 LBS</p>
                          </td>
                        </tr>
                        <tr className="weight-capacity">
                          <th>Weight Capacity</th>
                          <td>
                            <p>60 LBS</p>
                          </td>
                        </tr>
                        <tr className="width">
                          <th>Width</th>
                          <td>
                            <p>24″</p>
                          </td>
                        </tr>
                        <tr className="handle-height-ground-to-handle">
                          <th>Handle height (ground to handle)</th>
                          <td>
                            <p>37-45″</p>
                          </td>
                        </tr>
                        <tr className="wheels">
                          <th>Wheels</th>
                          <td>
                            <p>12″ air / wide track slick tread</p>
                          </td>
                        </tr>
                        <tr className="seat-back-height">
                          <th>Seat back height</th>
                          <td>
                            <p>21.5″</p>
                          </td>
                        </tr>
                        <tr className="head-room-inside-canopy">
                          <th>Head room (inside canopy)</th>
                          <td>
                            <p>25″</p>
                          </td>
                        </tr>
                        <tr className="pa_color">
                          <th>Color</th>
                          <td>
                            <p>Black, Blue, Red, White</p>
                          </td>
                        </tr>
                        <tr className="pa_size">
                          <th>Size</th>
                          <td>
                            <p>M, S</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {activeTabs === 2 && (
                <div className="tabContent">
                  <div className="row">
                    <div className="col-md-8">
                      <h3>Customer questions & answers</h3>
                      <br />

                      {reviewsData?.length !== 0 &&
                        reviewsData
                          ?.slice(0)
                          ?.reverse()
                          ?.map((item, index) => {
                            return (
                              <div
                                className="reviewBox mb-4 border-bottom"
                                key={index}
                              >
                                <div className="info">
                                  <div className="d-flex align-items-center w-100">
                                    <h5>{item?.customerName}</h5>

                                    <div className="ml-auto">
                                      <Rating
                                        name="half-rating-read"
                                        value={item?.customerRating}
                                        readOnly
                                        size="small"
                                      />
                                    </div>
                                  </div>

                                  <h6 className="text-light">
                                    {item?.dateCreated}
                                  </h6>

                                  <p>{item?.review} </p>
                                </div>
                              </div>
                            );
                          })}

                      <br className="res-hide" />

                      <form className="reviewForm" onSubmit={addReview}>
                        <h4>Add a review</h4>
                        <div className="form-group">
                          <textarea
                            className="form-control shadow"
                            placeholder="Write a Review"
                            name="review"
                            value={reviews.review}
                            onChange={onChangeInput}
                          ></textarea>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <Rating
                                name="rating"
                                value={rating}
                                precision={0.5}
                                onChange={changeRating}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <div className="form-group">
                          <Button
                            type="submit"
                            className="btn-blue btn-lg btn-big btn-round"
                          >
                            {isLoading === true ? (
                              <CircularProgress
                                color="inherit"
                                className="loader"
                              />
                            ) : (
                              "Submit Review"
                            )}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <br />

          {relatedProductData?.length !== 0 && (
            <RelatedProducts
              title="RELATED PRODUCTS"
              data={relatedProductData}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
