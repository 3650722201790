import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { IoBagCheckOutline } from "react-icons/io5";

import { MyContext } from "../../App";
import { editData, fetchDataFromApi, postData } from "../../utils/api";

import { useNavigate } from "react-router-dom";
import axios from 'axios';
const Checkout = () => {
  const [formFields, setFormFields] = useState({
    name: "",
    country: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    townCity: "",
    state: "",
    postCode: "",
    phone: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [otherCharges, setOtherCharges] = useState(0);

  var otherCharges_ = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    fetchDataFromApi(`/api/cart?buyerCode=${user?.userId}`).then((res) => {
      setCartData(res);

      res?.length !== 0 &&
        res?.map((item) => {
          otherCharges_ +=
            parseInt(item?.otherCharges) * parseInt(item?.quantity);
        });
      setTotalAmount(
        res?.length !== 0
          ? res
              ?.map(
                (item) =>
                  parseInt(item?.quantity) * parseInt(item?.pricePerUnit)
              )
              .reduce((total, value) => total + value, 0) + otherCharges_
          : 0
      );

      // setOtherCharges(otherCharges_);
    });

    const userInfo = JSON.parse(localStorage.getItem("user"));

    fetchDataFromApi(`/api/user/${userInfo?.userId}`).then((res) => {
      formFields.name = res?.name;
      formFields.country = res?.address?.country;
      formFields.streetAddressLine1 = res?.address?.streetAddressLine1;
      formFields.streetAddressLine2 = res?.address?.streetAddressLine1;
      formFields.townCity = res?.address?.townCity;
      formFields.state = res?.address?.state;
      formFields.postCode = res?.address?.postCode;
      formFields.phone = userInfo?.phone;
      formFields.email = userInfo?.email;

      setIsLoading(false);

    });
  }, []);

  const onChangeInput = (e) => {
    setFormFields(() => ({
      ...formFields,
      [e.target.name]: e.target.value,
    }));
  };

  const context = useContext(MyContext);
  const history = useNavigate();

  const checkout = (e) => {
    e.preventDefault();

    const userInfo = JSON.parse(localStorage.getItem("user"));

    console.log(formFields);
    const addressInformation = {
      country: formFields.country,
      streetAddressLine1: formFields.streetAddressLine1,
      streetAddressLine2: formFields.streetAddressLine2,
      townCity: formFields.townCity,
      state: formFields.state,
      postCode: formFields.postCode,
    };

    if (formFields.name === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill full name ",
      });
      return false;
    }

    if (formFields.country === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill country ",
      });
      return false;
    }

    if (formFields.streetAddressLine1 === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill Street address",
      });
      return false;
    }

    if (formFields.streetAddressLine2 === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill  Street address",
      });
      return false;
    }

    if (formFields.townCity === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill city ",
      });
      return false;
    }

    if (formFields.state === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill state ",
      });
      return false;
    }

    if (formFields.postCode === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill zipCode ",
      });
      return false;
    }

    if (formFields.phone === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill phone Number ",
      });
      return false;
    }

    if (formFields.email === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill email",
      });
      return false;
    }

    const addressInfo = {
      name: formFields.name,
      phoneNumber: formFields.phone,
      address: formFields.streetAddressLine1 + formFields.streetAddressLine2,
      pincode: formFields.postCode,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };

    const userData = {};

    fetchDataFromApi(`/api/user/${userInfo?.userId}`).then((res) => {
      userData.name = res?.name;
      userData.phone = res?.phone;
      userData.email = res?.email;
      userData.images = res?.images;
      userData.isAdmin = res?.isAdmin;
      userData.isVendor = res?.isVendor;
      userData.userCode = res?.userCode;
      userData.address = addressInformation;

      editData(`/api/user/${userInfo?.userId}`, userData).then((res) => {});
    });

    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      key_secret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
      amount: parseInt(totalAmount * 100),
      currency: "INR",
      order_receipt: "order_rcptid_" + formFields.fullName,
      name: "E-Bharat",
      description: "for testing purpose",
      handler: function (response) {
        console.log(response);

        const paymentId = response.razorpay_payment_id;

        const user = JSON.parse(localStorage.getItem("user"));

        const payLoad = {
          name: addressInfo.name,
          phoneNumber: formFields.phone,
          address: addressInfo.address,
          pincode: addressInfo.pincode,
          amount: parseInt(totalAmount * 100),
          paymentId: paymentId,
          email: user.email,
          userid: user.userId,
          products: cartData,
        };

        // console.log(payLoad)

        postData(`/api/orders/create`, payLoad).then((res) => {
          history("/orders");
        });
      },

      theme: {
        color: "#3399cc",
      },
    };

    var pay = new window.Razorpay(options);
    pay.open();
  };


  const handlePayment = async (e) =>{
    e.preventDefault();

     const userInfo = JSON.parse(localStorage.getItem("user"));

      
    const addressInformation = {
      country: formFields.country,
      streetAddressLine1: formFields.streetAddressLine1,
      streetAddressLine2: formFields.streetAddressLine2,
      townCity: formFields.townCity,
      state: formFields.state,
      postCode: formFields.postCode,
    };

    if (formFields.name === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill full name ",
      });
      return false;
    }

    if (formFields.country === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill country ",
      });
      return false;
    }

    if (formFields.streetAddressLine1 === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill Street address",
      });
      return false;
    }

    if (formFields.streetAddressLine2 === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill  Street address",
      });
      return false;
    }

    if (formFields.townCity === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill city ",
      });
      return false;
    }

    if (formFields.state === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill state ",
      });
      return false;
    }

    if (formFields.postCode === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill zipCode ",
      });
      return false;
    }

    if (formFields.phone === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill phone Number ",
      });
      return false;
    }

    if (formFields.email === "") {
      context.setAlertBox({
        open: true,
        error: true,
        msg: "Please fill email",
      });
      return false;
    }


     const addressInfo = {
      name: formFields.name,
      phoneNumber: formFields.phone,
      address: formFields.streetAddressLine1 + formFields.streetAddressLine2,
      pincode: formFields.postCode,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };

    const userData = {};

    fetchDataFromApi(`/api/user/${userInfo?.userId}`).then((res) => {
      userData.name = res?.name;
      userData.phone = res?.phone;
      userData.email = res?.email;
      userData.images = res?.images;
      userData.isAdmin = res?.isAdmin;
      userData.isVendor = res?.isVendor;
      userData.userCode = res?.userCode;
      userData.address = addressInformation;

      editData(`/api/user/${userInfo?.userId}`, userData).then((res) => {});
    });



    var payLoad = {
          name: '',
          phoneNumber: '',
          address: '',
          pincode: '',
          amount: '',
          paymentId: '',
          email: '',
          userid: '',
          products: [],
        };

  const user = JSON.parse(localStorage.getItem("user"));
     
          payLoad.name= addressInfo.name;
          payLoad.phoneNumber= formFields.phone;
          payLoad.address= addressInfo.address;
          payLoad.pincode= addressInfo.pincode;
          payLoad.amount= totalAmount;
          payLoad.paymentId= '525214';
          payLoad.email= user.email;
          payLoad.userid= user.userId;
          payLoad.products= cartData;
       

  
    try {
     const response = await axios.post('http://localhost:8000/api/orders/create', payLoad)

      // const response = await axios.post('http://localhost:8000/api/pay-now/pay', data)
      // console.log(response.data)
      window.location.href = response.data.url
    } catch (error) {
      console.log("error in payment", error)
    }
  }


  return (
  <>
    <section className="section">
      <div className="container">
        <form className="checkoutForm" onSubmit={handlePayment}>
          <div className="row">
            <div className="col-md-8">
              <h2 className="hd">BILLING DETAILS</h2>

              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Full Name *"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="name"
                      disabled={true}
                      value={formFields?.name}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Country *"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="country"
                      value={formFields?.country}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Street address *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="House number and street name"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine1"
                      value={formFields?.streetAddressLine1}
                      onChange={onChangeInput}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      label="Apartment, suite, unit, etc. (optional)"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="streetAddressLine2"
                      value={formFields?.streetAddressLine2}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Town / City *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="City"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="townCity"
                      value={formFields?.townCity}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>State / County *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="State"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="state"
                      value={formFields?.state}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <h6>Postcode / ZIP *</h6>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextField
                      label="ZIP Code"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="postCode"
                      value={formFields?.postCode}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="phone"
                      disabled={true}
                      value={formFields.phone}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <TextField
                      label="Email Address"
                      variant="outlined"
                      className="w-100"
                      size="small"
                      name="email"
                      disabled={true}
                      value={formFields.email}
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card orderInfo">
                <h4 className="hd">YOUR ORDER</h4>
                <div className="table-responsive mt-3">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <b>Subtotal</b>{" "}
                        </td>

                        <td>&#8377; {totalAmount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Button
                  type="submit"
                  className="btn-blue bg-red btn-lg btn-big"
                >
                  <IoBagCheckOutline /> &nbsp; Pay Now
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
    {isLoading === true && <div className="loadingOverlay"></div>}
    </>
  );
};

export default Checkout;
