import React, { useState } from "react";

const QtyInput = (props) => {
  const [qtyValue, setQtyValue] = useState(0);
  const onChangeInput=(e)=>{
    setQtyValue(e.target.value);
    props.onChange(e.target.value, props?.dataId);
  }
  return (
    <input
      type="number"
      className="inputBox"
      value={qtyValue}
      onChange={onChangeInput}
      id={props?.id}
    />
  );
};

export default QtyInput;
